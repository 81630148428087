















































































































import { getBrCurrencyMaskedValue, lowerFirstLetter } from "@/@core/utils/utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import { BFormInput, BTableSimple, BThead, BTh, BTr, BTbody, BTd, BButton, BLink } from "bootstrap-vue";
import CurrencyInput from "./CurrencyInput.vue";

import projectModel from "@/api/project.model";
import { getBudgetSubtotalValueByItems, getMonthsSubtotalByItems } from "../projectApi";
import FileField from "@/views/entity/FileField.vue";

@Component({
	filters: {
		BRCurrency: getBrCurrencyMaskedValue,
	},
	components: {
		CurrencyInput,
		BFormInput,
		BTableSimple,
		BThead,
		BTbody,
		BTr,
		BTh,
		BTd,
		BButton,
		BLink,
		FileField,
	},
})
export default class ProjectBudgetEditTable extends Vue {
	@Prop({ default: false }) readOnly: boolean;
	@Prop() budgetField: any;
	@Prop() budgetItems: any;
	@Prop() executionMonthsPeriod: number;
	@Prop() fieldsDescription: any;
	model = projectModel;

	getItemKeyDescription(itemKey: string) {
		return this.budgetDescription[itemKey]?.kind ?? "";
	}

	showDocLabelCondition(budgetItem: any): boolean {
		return !budgetItem?.docUrl?.file && this.readOnly;
	}

	get estimatedMonthlyCostsSubtotal() {
		return this.budgetItems
			.map(({ estimatedMonthlyCosts }: any) => estimatedMonthlyCosts)
			.reduce((prevCost: any, curCost: any) => prevCost + curCost, 0);
	}

	get itemsSubtotal() {
		return getBudgetSubtotalValueByItems(this.budgetItems, this.executionMonthsPeriod, true);
	}

	get monthsSubtotal() {
		return getMonthsSubtotalByItems(this.budgetItems);
	}

	get subtotalName() {
		return `Subtotal de ${lowerFirstLetter(this.budgetField.name)}`;
	}

	get budgetDescription() {
		const budgetItemsFieldKey = "budgetItems";
		return Object.fromEntries(
			Object.values(this.model.description.filter(({ join }: any) => join === budgetItemsFieldKey)).map(
				(value: any) => [value.key.substring(budgetItemsFieldKey.length + 1, value.key.length), value],
			),
		);
	}

	get budgetColumns() {
		return [
			"name",
			"description",
			"docUrl",
			"quantity",
			"estimatedMonthlyCosts",
			...Array.from({ length: this.executionMonthsPeriod }, (_, i) => `Mês ${i + 1}`),
			"Total",
			"proponentSelfResources",
		]
			.map(
				column => this.model.description.find((field: any) => field.key === `budgetItems.${column}`)?.name ?? column,
			)
			.filter(column => column);
	}

	beforeDestroy() {
		if (!this.readOnly) {
			this.budgetItems.forEach((item: any) => {
				if (item.docUrl && !item.docUrl.file) {
					item.docUrl = null;
				}
			});
		}
	}

	onEstimatedMonthlyCostsChange(value: number, budgetItem: any) {
		budgetItem.estimatedMonthlyCosts = Math.round(value * 100);
		budgetItem.monthsCosts = [...budgetItem.monthsCosts.fill(budgetItem.estimatedMonthlyCosts)];
	}

	onMonthCostChange(val: number, budgetItem: any, monthIndex: number) {
		budgetItem.monthsCosts[monthIndex] = Math.round(val * 100);
		budgetItem.monthsCosts = [...budgetItem.monthsCosts];
	}

	removeItem(id: string) {
		const index = this.budgetItems.findIndex(({ id: registryId }: any) => registryId === id);
		this.budgetItems.splice(index, 1);
	}

	addRow() {
		const newItem = Object.fromEntries(
			Object.entries(this.fieldsDescription)
				.filter(([key]: any) => ["monthsCosts", this.budgetColumns.includes(key)])
				.map(([fieldKey, field]: any) => {
					if (field.kind === "relation" && field.relationType === "one-to-many") {
						return [fieldKey, [{}]];
					}
					if (fieldKey === "monthsCosts") {
						return [fieldKey, Array.from({ length: this.executionMonthsPeriod }, () => 0)];
					}
					if (fieldKey === "budgetField") {
						return [fieldKey, this.budgetField.value];
					}

					return [fieldKey, undefined];
				}),
		);

		this.budgetItems.push(newItem);
	}
}
