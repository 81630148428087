var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',[_vm._v(_vm._s(_vm.budgetField.namePrefix)+" "+_vm._s(_vm.budgetField.name))]),_c('div',{staticClass:"table-form-field",class:_vm.readOnly ? 'py-1' : 'px-5 py-3'},[_c('b-table-simple',{staticClass:"mt-2",attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.budgetColumns),function(column,index){return _c('b-th',{key:index,staticStyle:{"min-width":"200px"}},[_vm._v(" "+_vm._s(column)+" ")])}),1)],1),_c('b-tbody',[_vm._l((_vm.budgetItems),function(budgetItem,itemIndex){return _c('b-tr',{key:budgetItem.id},[_vm._l((budgetItem),function(value,itemKey){return [(
								![
									'id',
									'createdDate',
									'updatedDate',
									'monthsCosts',
									'budgetField',
									'proponentSelfResources' ].includes(itemKey)
							)?_c('b-td',{key:itemKey},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(!_vm.readOnly && _vm.budgetItems.length !== 1 && itemKey === 'name')?_c('b-link',{staticClass:"text-danger mr-1",attrs:{"size":"sm"},on:{"click":function () { return _vm.removeItem(budgetItem.id); }}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"16"}})],1):_vm._e(),(_vm.getItemKeyDescription(itemKey) === 'text')?_c('b-form-input',{attrs:{"readOnly":_vm.readOnly},model:{value:(budgetItem[itemKey]),callback:function ($$v) {_vm.$set(budgetItem, itemKey, $$v)},expression:"budgetItem[itemKey]"}}):_vm._e()],1),(_vm.getItemKeyDescription(itemKey) === 'file')?[(_vm.showDocLabelCondition(budgetItem))?_c('div',{staticClass:"text-center"},[_vm._v("-")]):_c('file-field',_vm._b({},'file-field',{
										readOnly: _vm.readOnly,
										isDeflatedEntity: false,
										model: _vm.model,
										field: _vm.fieldsDescription[itemKey],
										entity: budgetItem,
										showLabel: false,
										kind: 'button',
									},false))]:(itemKey === 'estimatedMonthlyCosts')?_c('currency-input',{attrs:{"readOnly":_vm.readOnly,"value":budgetItem.estimatedMonthlyCosts / 100},on:{"change":function (val) { return _vm.onEstimatedMonthlyCostsChange(val, budgetItem); }}}):_vm._e()],2):(itemKey === 'monthsCosts')?_vm._l((value),function(monthValue,monthIndex){return _c('b-td',{key:monthIndex},[_c('currency-input',{attrs:{"readOnly":_vm.readOnly,"value":monthValue / 100},on:{"change":function (val) { return _vm.onMonthCostChange(val, budgetItem, monthIndex); }}})],1)}):_vm._e()]}),_c('b-td',{staticClass:"text-primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("BRCurrency")(_vm.monthsSubtotal[itemIndex]))+" ")]),_c('b-td',[_c('currency-input',{attrs:{"readOnly":_vm.readOnly,"value":budgetItem.proponentSelfResources / 100},on:{"change":function($event){budgetItem.proponentSelfResources = $event * 100}}})],1)],2)}),_c('b-tr',[_c('b-td',{attrs:{"colspan":_vm.readOnly ? 3 : 4}},[_vm._v(_vm._s(_vm.subtotalName))]),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("BRCurrency")(_vm.estimatedMonthlyCostsSubtotal))+" ")]),_vm._l((_vm.itemsSubtotal.monthsCosts),function(monthSubtotal,index){return _c('b-td',{key:index},[_vm._v(_vm._s(_vm._f("BRCurrency")(monthSubtotal))+" ")])}),_c('b-td',{staticClass:"text-primary font-weight-bold"},[_vm._v(_vm._s(_vm._f("BRCurrency")(_vm.itemsSubtotal.monthsCostsTotal)))]),_c('b-td',[_vm._v(_vm._s(_vm._f("BRCurrency")(_vm.itemsSubtotal.proponentSelfResources)))])],2)],2)],1),(!_vm.readOnly)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-link',{staticClass:"text-tertiary font-weight-bold",on:{"click":_vm.addRow}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon"}}),_vm._v("Adicionar item")],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }