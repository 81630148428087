<template>
	<b-form-input ref="inputRef" type="text" />
</template>

<script>
	import { watch } from "@vue/composition-api";
	import { useCurrencyInput } from "vue-currency-input";
	import { BFormInput } from "bootstrap-vue";

	export default {
		name: "CurrencyInput",
		props: {
			value: Number,
		},
		components: {
			BFormInput,
		},
		setup(props) {
			const { inputRef, setOptions, setValue } = useCurrencyInput({
				currency: "BRL",
				locale: "pt-BR",
				precision: 2,
				valueRange: {
					max: 99999999.99
				},
				autoDecimalDigits: true,
				hideCurrencySymbolOnFocus: false,
				hideGroupingSeparatorOnFocus: false,
			});

			watch(
				() => props.value,
				value => {
					setValue(value);
				},
			);

			watch(
				() => props.options,
				options => {
					setOptions(options);
				},
			);

			return { inputRef };
		},
	};
</script>
