export function getBudgetTotalFieldsValues(budgetItemsObject: Record<string, any[]>) {
	const totals = Object.values(budgetItemsObject).map(getMonthsSubtotalSumByItems);
	const total = totals.reduce((prevValue, curValue) => prevValue + curValue, 0);

	return Object.entries(budgetItemsObject).map(([itemsKey], itemsIndex) => {
		const percentage = (totals[itemsIndex] * 100) / total;
		return {
			budgetField: itemsKey,
			total: totals[itemsIndex],
			percentage: isNaN(percentage) ? 0 : percentage,
		};
	});
}

export function getBudgetTotalMonthsValues(
	budgetItemsObject: Record<string, any[]>,
	projectExecutionMonthsPeriod: number,
): any {
	const items = Object.values(budgetItemsObject).flat(1);

	return getBudgetSubtotalValueByItems(items, projectExecutionMonthsPeriod, true, false);
}

export function getBudgetSubtotalValues(
	budgetItemsObject: Record<string, any[]>,
	projectExecutionMonthsPeriod: number,
	showMonthsSubTotal = false,
): any {
	const subtotal: any = {};

	Object.keys(budgetItemsObject).forEach(key => {
		subtotal[key] = getBudgetSubtotalValueByItems(
			budgetItemsObject[key],
			projectExecutionMonthsPeriod,
			showMonthsSubTotal,
		);
	});

	return subtotal;
}

export function getBudgetSubtotalValueByItems(
	budget: any[],
	projectExecutionMonthsPeriod: number,
	showMonthsSubTotal = false,
	includeBudgetField = true,
): any {
	const budgetNewItems = JSON.parse(JSON.stringify(budget));

	const subtotal = budgetNewItems.reduce(
		(prevItem: any, curItem: any) => {
			prevItem.monthsCosts.forEach((monthCost: number, monthIndex: number) => {
				curItem.monthsCosts[monthIndex] = curItem.monthsCosts[monthIndex] + monthCost;
			});

			const subTotalItem = {
				proponentSelfResources: prevItem.proponentSelfResources + curItem.proponentSelfResources,
				monthsCosts: curItem.monthsCosts,
			};

			if (includeBudgetField) {
				Object.assign(subTotalItem, { budgetField: curItem.budgetField });
			}

			return subTotalItem;
		},
		{
			monthsCosts: Array.from({ length: projectExecutionMonthsPeriod }, () => 0),
			proponentSelfResources: 0,
		},
	);

	if (showMonthsSubTotal) {
		return Object.assign(subtotal, {
			monthsCostsTotal: getMonthsSubtotalByItems(budget).reduce(
				(prevSubTotal: number, curSubtotal: number) => prevSubTotal + curSubtotal,
				0,
			),
		});
	}

	return subtotal;
}

export function getMonthsSubtotalByItems(budgetItems: any[]): number[] {
	return budgetItems.map(({ monthsCosts }: any) =>
		monthsCosts.reduce((prevMonth: number, curMonth: number) => prevMonth + curMonth, 0),
	);
}

function getMonthsSubtotalSumByItems(budgetItems: any[]): number {
	return getMonthsSubtotalByItems(budgetItems).reduce(
		(prevSubTotal: number, curSubtotal: number) => prevSubTotal + curSubtotal,
		0,
	);
}
