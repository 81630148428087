














import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ProjectStepTab extends Vue {
	@Prop({ required: true }) stepForms: any[];
	@Prop({ required: true }) currentStep: any;
	@Prop({ required: true }) updateCurrentStep: Function;
}
