





import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";

@Component({
	components: { VueApexCharts },
})
export default class ProjectBudgetChart extends Vue {
	@Prop({ required: true }) values: any[];
	@Prop({ required: true }) labels: any[];

	get chartOptions() {
		return {
			chart: {
				type: "donut",
			},
			colors: ["#A9CF39", "#002CD0", "#970081", "#FADB4A", "#006900", "#DB5D5D", "#C7D0D5", "#696969"],
			stroke: {
				show: false,
			},
			legend: {
				show: true,
				position: "bottom",
				fontSize: "14px",
				fontFamily: "Montserrat, Helvetica, Arial, serif",
				horizontalAlign: "start",
				fontWeight: 400,
				width: 220,
				labels: {
					colors: ["#696969"],
				},
				markers: {
					width: 12,
					height: 12,
					strokeColor: "#fff",
					radius: 4,
					offsetX: -5,
				},
			},
			tooltip: {
				enabled: false,
			},
			labels: this.labels,
			plotOptions: {
				pie: {
					donut: {
						size: "87%",
						labels: {
							show: true,
							name: {
								show: false,
							},
							value: {
								show: true,
								fontFamily: "Montserrat, Helvetica, Arial, serif",
								fontSize: "24px",
								fontWeight: 700,
								color: "#6D6E70",
								formatter: (val: number) => `${val}%`.replace(".", ","),
							},
						},
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
		};
	}
}
