




























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
	BModal,
	BContainer,
	BRow,
	BCol,
	BTableSimple,
	BThead,
	BTh,
	BTr,
	BTbody,
	BTd,
	BButton,
	BLink,
} from "bootstrap-vue";
import { getBudgetSubtotalValues } from "../projectApi";

import ProjectBudgetEditTable from "./ProjectBudgetEditTable.vue";
import { getBrCurrencyMaskedValue, lowerFirstLetter } from "@/@core/utils/utils";
import ProjectBudgetChart from "./ProjectBudgetChart.vue";
import ProjectBudgetTotals from "./ProjectBudgetTotals.vue";

@Component({
	filters: {
		BRCurrency: getBrCurrencyMaskedValue,
	},
	components: {
		BModal,
		BContainer,
		BRow,
		BCol,
		BTableSimple,
		BThead,
		BTh,
		BTr,
		BTbody,
		BTd,
		BButton,
		BLink,
		ProjectBudgetEditTable,
		ProjectBudgetChart,
		ProjectBudgetTotals,
	},
})
export default class ProjectBudgetPreview extends Vue {
	@Prop({ default: {} }) field: any;
	@Prop({}) entity: any;
	@Prop({}) fieldsDescription: any;
	isModalOpened = false;
	currentModalBudgetField = {};
	currentBudgetItems = [];
	budgetSubtotalValues: any = {};

	get monthsLabels() {
		return Array.from({ length: this.entity.executionMonthsPeriod }, (_, i) => `Mês ${i + 1}`);
	}

	get budgetFieldsDescription() {
		return Object.fromEntries(
			Object.values(this.fieldsDescription.budgetField.options).map((field: any) => [field.value, field]),
		);
	}

	mounted() {
		this.calculateBudgetTotals();
	}

	getBudgetFieldNameWithPrefix(key: string): string {
		const { namePrefix = "", name = "" } = this.budgetFieldsDescription[key];
		return `${namePrefix} ${name}`;
	}

	getBudgetFieldSubtotalName(key: string) {
		return `Subtotal de ${lowerFirstLetter(this.budgetFieldsDescription[key]?.name ?? "")}`;
	}

	@Watch("currentBudgetItems")
	calculateBudgetTotals() {
		const entityValue = this.entity["budget"];

		this.budgetSubtotalValues = getBudgetSubtotalValues(entityValue, this.entity.executionMonthsPeriod);
	}

	toggleBudgetEditModal(value: boolean, budgetField = "") {
		this.currentModalBudgetField = value ? this.budgetFieldsDescription[budgetField] : [];
		this.currentBudgetItems = value ? this.entity["budget"][budgetField] : {};

		this.isModalOpened = value;
	}
}
