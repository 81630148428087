


























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { getBudgetTotalMonthsValues, getBudgetTotalFieldsValues } from "../projectApi";
import { BContainer, BRow, BCol, BTableSimple, BTbody, BTd, BTh, BThead, BTr } from "bootstrap-vue";
import { getBrCurrencyMaskedValue } from "@/@core/utils/utils";
import ProjectBudgetChart from "./ProjectBudgetChart.vue";
import projectModel from "@/api/project.model";

@Component({
	filters: {
		BRCurrency: getBrCurrencyMaskedValue,
	},
	components: {
		BContainer,
		BRow,
		BCol,
		BTableSimple,
		BThead,
		BTh,
		BTr,
		BTbody,
		BTd,
		ProjectBudgetChart,
		VueApexCharts,
	},
})
export default class ProjectBudgetTotals extends Vue {
	@Prop({ required: true }) entity: any;
	budgetSubtotalValues: any = {};
	budgetTotalMonthsValues: any = {};
	budgetTotalFieldsValues: any = [];
	model = projectModel;

	get budgetTotal() {
		return this.budgetTotalFieldsValues.reduce(
			(prevValue: any, curValue: any) => ({
				total: prevValue.total + curValue.total,
				percentage: 100,
			}),
			{ total: 0, percentage: 0 },
		);
	}

	get budgetFieldsDescription() {
		return this.model.budgetFieldsDescription;
	}

	get fieldsLabels() {
		return this.budgetTotalFieldsValues.map(
			({ budgetField }: any) => this.model.budgetFieldsDescription[budgetField],
		);
	}

	get monthsLabels() {
		return Array.from({ length: this.entity.executionMonthsPeriod }, (_, i) => `Mês ${i + 1}`);
	}

	get fieldsTotals() {
		return this.budgetTotalFieldsValues.map(({ percentage }: any) => Number(percentage.toFixed(1)));
	}

	mounted() {
		this.calculateBudgetTotals();
	}

	@Watch("entity.budget", { deep: true })
	calculateBudgetTotals() {
		this.budgetTotalMonthsValues = getBudgetTotalMonthsValues(this.entity.budget, this.entity.executionMonthsPeriod);
		this.budgetTotalFieldsValues = getBudgetTotalFieldsValues(this.entity.budget);
	}
}
