import cityModel from "./city.model";
import { ProjectPayload } from "./project_payload";
import { CrudModel } from "./_crud";
import { crudGet, crudPatch, crudPost, deflateEntity } from "./_request";

export class ProjectModel extends CrudModel {
	constructor() {
		super("admin/project");
	}

	formColumns: any[] = [];

	get budgetFieldsDescription() {
		if (!this.description.length) {
			return [];
		}

		return Object.fromEntries(
			Object.values(
				this.description
					.find((field: any) => field.key === "budgetItems.budgetField")!
					.options.map(({ value, name }: any) => [value, name]),
			),
		);
	}

	async search(seachParamsObj: Record<string, any>) {
		const response = await crudPost(this.endpoint, "get_many", seachParamsObj);

		if (response.data && Array.isArray(response.data)) {
			response.data = response.data.map((entity: any) => deflateEntity(entity));
		}

		return response;
	}

	private async setFormColumns() {
		await this.getDescription();

		const mainFieldsKeys = this.description.filter((field: any) => field.namePrefix).map(({ key }: any) => key);

		this.formColumns = mainFieldsKeys.map((key: any) => {
			let tableFields: any = [];

			if (["monitoringPlanSteps", "communicationPlanIndicators", "responsibleMembers"].includes(key)) {
				tableFields = this.description
					.filter(
						(field: any) => field?.join?.match(key) && !field.key.match(/selectedMonths/g) && field.kind !== "relation",
					)
					.map((field: any) => {
						const parts = field.key.split(".");
						return {
							key: parts[parts.length - 1],
							label: field.name,
						};
					});
			} else if (key === "workSchedule") {
				tableFields = {
					monitoringPlanSteps: [],
					communicationPlanIndicators: [],
				};
				for (const field of this.description.filter(({ key }: any) =>
					[
						"monitoringPlanSteps.step",
						"monitoringPlanSteps.activities.activity",
						"monitoringPlanSteps.activities.selectedMonths",
						"communicationPlanIndicators.producedMaterial",
						"communicationPlanIndicators.selectedMonths",
					].includes(key),
				)) {
					const parts = field.key.split(".");
					const tableFieldKey = field.key.includes("Month") ? "monthRange" : parts[parts.length - 1];
					if (field.key.startsWith("monitoringPlanSteps")) {
						if (
							field.key.includes("Month") &&
							tableFields.monitoringPlanSteps.map(({ key }: any) => key).includes("monthRange")
						) {
							continue;
						}
						tableFields.monitoringPlanSteps.push({ key: tableFieldKey, label: field.name });
					} else {
						if (
							field.key.includes("Month") &&
							tableFields.communicationPlanIndicators.map(({ key }: any) => key).includes("monthRange")
						) {
							continue;
						}
						tableFields.communicationPlanIndicators.push({ key: tableFieldKey, label: field.name });
					}
				}
			}

			return {
				key,
				tableFields,
			};
		});
	}

	async getStep(id: string, step: string) {
		await this.setFormColumns();
		const stepData = await crudGet("app/project", `${id}/step/${step}`);

		if (step === "1") {
			this.description[
				this.description.findIndex((description: any) => description.key === "executionCounties")
			].options = await cityModel.search({});
			stepData.executionCounties = stepData.executionCounties?.map(({ county }: any) => county);
		}
		delete stepData.status;

		return stepData;
	}

	async readPreviewProject(id: string) {
		await this.setFormColumns();

		return crudGet("app/project", id);
	}

	async saveStep(projectId: string, stepNumber: string, stepData: any) {
		return crudPatch(
			"admin/project",
			`${projectId}/step/${stepNumber}`,
			new ProjectPayload(stepData, Number(stepNumber)),
		);
	}
}
export default new ProjectModel();
