import { CrudModel, EntityDescription } from "./_crud";
import { crudQuery } from "./_request";

export class CityModel extends CrudModel {
	constructor() {
		super("city");
	}

	async getDescription(): Promise<EntityDescription> {
		return [];
	}

	async loadCities() {
		return crudQuery("city", "");
	}
}
export default new CityModel();
