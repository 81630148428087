





































































































































































import projectModel from "@/api/project.model";
import {
	BTableSimple,
	BThead,
	BTh,
	BTr,
	BTbody,
	BTd,
	BButton,
	BFormInput,
	BLink,
	BFormRadio,
	BFormRadioGroup,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectBudgetPreview from "./ProjectBudgetPreview.vue";
import MonthRangeSelector from "./MonthRangeSelector.vue";

@Component({
	components: {
		ProjectBudgetPreview,
		BFormInput,
		BTableSimple,
		BThead,
		BTh,
		BTr,
		BTd,
		BTbody,
		BButton,
		BLink,
		MonthRangeSelector,
		BFormRadioGroup,
		BFormRadio,
	},
})
export default class ProjectTableFormField extends Vue {
	@Prop({ default: {} }) field: any;
	@Prop() entity: any;

	get isWorkScheduleField(): boolean {
		return this.field.key === "workSchedule";
	}

	get isPlanStepsField(): boolean {
		return this.field.key === "monitoringPlanSteps";
	}

	get tableColumns() {
		return projectModel.formColumns.find(({ key }) => key === this.field.key)?.tableFields;
	}

	get tableColumnsKeys() {
		return this.tableColumns.map(({ key }: any) => key);
	}

	get entityValue() {
		return this.entity[this.field.key];
	}

	get fieldsDescription() {
		return Object.fromEntries(
			Object.values(projectModel.description.filter((field: any) => field?.join === this.field.key)).map(
				(value: any) => {
					const keyName = value.key.substring(this.field.key.length + 1, value.key.length);

					return [keyName, { ...value, key: keyName }];
				},
			),
		);
	}

	created() {
		if (this.isPlanStepsField) {
			this.entityValue.forEach(({ id, activities }: any) => {
				if (!activities.length) {
					this.addActivity(id);
				}
			});
		}
	}

	getRadioGroupOptions(options: any[]) {
		return options?.map((option: any) => ({
			text: option.name,
			value: option.value,
		}));
	}

	removeRegistryById(id: string) {
		const index = this.entityValue.findIndex(({ id: registryId }: any) => registryId === id);
		this.entityValue.splice(index, 1);
	}

	removeActivity(planStepIndex: number, activityIndex: number) {
		const activities = this.entityValue[planStepIndex].activities;

		if (activities.length === 1) {
			activities.splice(activityIndex, 1, {});
		} else {
			activities.splice(activityIndex, 1);
		}
	}

	addActivity(planStepId: string) {
		const stepIndex = this.entityValue.findIndex((planStep: any) => planStep.id === planStepId);
		this.entity[this.field.key][stepIndex].activities.push({});
	}

	addRow() {
		const newRowObj = Object.fromEntries(
			Object.entries(this.fieldsDescription)
				.filter(([key]: any) => this.isPlanStepsField || this.tableColumnsKeys.includes(key))
				.map(([fieldKey, field]: any) => {
					if (field.kind === "relation" && field.relationType === "one-to-many") {
						return [fieldKey, [{}]];
					}

					return [fieldKey, undefined];
				}),
		);

		this.entity[this.field.key].push(newRowObj);
	}

	getTableRowSpan(activitiesLength: number): number {
		return this.isWorkScheduleField ? activitiesLength : activitiesLength + 1 || 1;
	}
}
