

















































import vSelect from "vue-select";
import { BFormInput, BFormRadioGroup, BFormRadio, BFormTextarea, BButton } from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectTableFormField from "./ProjectTableFormField.vue";
import { downloadFile } from "@/@core/utils/utils";
import { HYDROGRAPHIC_BASINS_LIST_FILE_LINK } from "@/api/_request";

@Component({
	components: { BButton, BFormInput, BFormTextarea, vSelect, ProjectTableFormField, BFormRadioGroup, BFormRadio },
})
export default class ProjectFormField extends Vue {
	@Prop({ default: {} }) field: any;
	@Prop() entity: any;

	get fieldMaxLengthLabel() {
		const maxLength = this.field?.validation?.lengthMax;

		if (!maxLength) {
			return "";
		}

		return `(até ${maxLength} caracteres)`;
	}

	get fieldDescriptionHtml() {
		if (typeof this.field.description === "string") {
			return `<p>${this.field.description}</p>`;
		}
		// O segundo item será sempre em negrito
		if (Array.isArray(this.field.description)) {
			return `
			<p>
				${this.field.description[0]}
				<b>${this.field.description[1]}</b>
			</p>
		`;
		}
		return null;
	}

	get radioGroupOptions() {
		return this.field?.options?.map((option: any) => ({
			text: option.name,
			value: option.value,
		}));
	}

	async downloadHydrographicBasinsList() {
		await downloadFile(HYDROGRAPHIC_BASINS_LIST_FILE_LINK, "lista-de-municipios.pdf");
	}
}
