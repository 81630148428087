


















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
	components: {},
})
export default class MonthRangeSelector extends Vue {
	@Prop({ required: true }) entity: any;
	@Prop({ required: true }) totalMonths: number;
	@Prop({ default: "secondary" }) squareColor: "secondary" | "tertiary";
	@Prop({ default: false }) readOnly: boolean;

	selectedMonths: number[] = [];

	onDblClick(month: number) {
		if (!this.readOnly) {
			this.selectedMonths = [month];
			this.entity.selectedMonths = this.selectedMonths;
		}
	}

	onExactClick(month: number) {
		if (!this.readOnly) {
			if (!this.selectedMonths.includes(month)) {
				this.selectedMonths = [...this.selectedMonths, month];
				this.entity.selectedMonths = this.selectedMonths;
			}
		}
	}

	getSelectedMonthRangeCondition(month: number): boolean {
		if (this.entity.selectedMonths === null || this.entity.selectedMonths.length === 0) {
			return false;
		}

		return this.entity.selectedMonths.includes(month);
	}
}
