export class ProjectPayload {
	constructor(payload: any, step: number) {
		if (step === 1) {
			payload.executionMonthsPeriod = Number(payload.executionMonthsPeriod);
			payload.executionCountiesIds = payload.executionCounties.map(({ id }: any) => id);
		} else if (step === 2) {
			payload.monitoringPlanSteps.forEach((step: any) => {
				step.activities.forEach((activity: any) => {
					delete activity.selectedMonths;
				});
			});

			payload.communicationPlanIndicators.forEach((indicator: any) => {
				indicator.quantity = Number(indicator.quantity);

				delete indicator.selectedMonths;
			});
		} else if (step === 3) {
			payload.workSchedule.communicationPlanIndicators = payload.workSchedule.communicationPlanIndicators.map(
				(indicator: any) => ({
					id: indicator.id,
					selectedMonths: indicator.selectedMonths,
				}),
			);

			payload.workSchedule.monitoringPlanActivities = payload.workSchedule.monitoringPlanSteps
				.map((step: any) =>
					step.activities.map((activity: any) => ({
						id: activity.id,
						selectedMonths: activity.selectedMonths,
					})),
				)
				.flat();

			delete payload.workSchedule.monitoringPlanSteps;

			payload.responsibleMembers = payload.responsibleMembers.map((member: any) => ({
				...member,
				quantity: Number(member.quantity),
				weeklyHoursWorkload: Number(member.weeklyHoursWorkload),
			}));
		} else if (step === 4) {
			payload = payload.budget;

			Object.keys(payload).forEach(key => {
				payload[key].forEach((item: any) => {
					item.quantity = Number(item.quantity);
				});
			});
		}

		const fieldsByStep = [
			["title", "executionMonthsPeriod", "brief", "activityArea", "executionCountiesIds", "hydrographicBasins"],
			[
				"targetAudience",
				"justification",
				"objective",
				"monitoringPlanSteps",
				"methodology",
				"communicationPlanIndicators",
			],
			["workSchedule", "responsibleMembers", "additionalInformation"],
			[
				"indirectExpenses",
				"personal",
				"socialCharges",
				"generalExpenses",
				"events",
				"communication",
				"taxesAndFees",
				"material",
			],
		][step - 1];

		const stepPayload = Object.fromEntries(
			Object.keys(payload)
				.filter(key => fieldsByStep.includes(key))
				.map(key => [key, payload[key]]),
		);

		Object.assign(this, stepPayload);

		console.log(this);
	}
}
