






































import projectModel from "@/api/project.model";
import { showAlert, AlertKind } from "@/helpers";
import { BCard, BLink, BOverlay, BButton, BTab, BTabs } from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import ProjectFormField from "./components/ProjectFormField.vue";
import ProjectStepTabs from "./components/ProjectStepTab.vue";

@Component({
	components: { BTabs, BTab, BCard, BLink, BOverlay, BButton, ProjectFormField, ProjectStepTabs },
})
export default class ProjectForm extends Vue {
	isEntityLoading = false;
	currentStep = "1";
	entity: any = {};
	fields: any = [];
	id: string;

	readonly stepForms = [
		{
			step: "1",
			fields: ["title", "brief", "activityArea", "executionMonthsPeriod", "executionCounties", "hydrographicBasins"],
		},
		{
			step: "2",
			fields: [
				"targetAudience",
				"justification",
				"objective",
				"monitoringPlanSteps",
				"methodology",
				"communicationPlanIndicators",
			],
		},
		{
			step: "3",
			fields: ["workSchedule", "responsibleMembers", "additionalInformation"],
		},
		{
			step: "4",
			fields: ["budgetItems"],
		},
	];

	get currentStepForm() {
		return this.stepForms.find(stepForm => stepForm.step === this.currentStep)!;
	}

	updateCurrentStep(step: string): void {
		if (step !== this.currentStep) {
			this.$router.replace(this.$router.currentRoute.path.replace(`/etapa/${this.currentStep}`, `/etapa/${step}`));
		}
	}

	async saveStep() {
		this.isEntityLoading = true;
		try {
			delete this.entity.id;
			await projectModel.saveStep(this.id, this.currentStep, this.entity);
			await this.loadEntity();
			showAlert(AlertKind.SUCCESS, "Os dados foram registrados.");
		} catch (error: any) {
			console.error(error);
			showAlert(error?.kind ?? AlertKind.ERROR, error?.message || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isEntityLoading = false;
		}
	}

	async mounted() {
		await this.loadEntity();
	}

	@Watch("$route")
	async loadEntity() {
		this.isEntityLoading = true;
		try {
			this.currentStep = this.$route.params.step;
			this.id = this.$route.params.id;

			this.entity = await projectModel.getStep(this.id, this.currentStep);

			this.fields = this.currentStepForm?.fields.map(field =>
				projectModel.description.find((fieldDescription: any) => field === fieldDescription.key),
			);
		} catch (error) {
			console.error(error);
		} finally {
			this.isEntityLoading = false;
		}
	}

	getToProjectPreviewPage() {
		this.$router.replace(this.$router.currentRoute.path.replace(`/etapa/${this.currentStep}`, ""));
	}
}
